import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'

import Link from 'components/common/Link'
import Layout from 'components/Layout'
import Button from 'components/common/Button'
import {colors} from 'utils/styles'

import animation from './animation.gif'

const Page = ({t, navigate, location}) => (
  <Layout navigate={navigate} location={location}>
    <div style={{textAlign: 'center'}}>
      <img src={animation} style={{width: 400, marginBottom: 0}} />
    </div>
    <h1 style={{color: '#a1a1a1', fontSize: 45, textAlign: 'center'}}>
      {t('NotFoundPage.pageNotFound')}
    </h1>
    <p style={{color: '#a1a1a1', textAlign: 'center'}}>
      {t('NotFoundPage.explanatoryText')}
    </p>
    <div style={{textAlign: 'center'}}>
      <Link to=''>
        <Button
          style={{
            color: '#ffffff',
            cursor: 'pointer',
            padding: '10px 40px',
            border: 0,
            borderRadius: 3,
            backgroundColor: colors.lilac
          }}
        >
          {t('NotFoundPage.goToHomepage')}
        </Button>
      </Link>
    </div>
  </Layout>
)

Page.propTypes = {
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(Page)
